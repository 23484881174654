import axios from "axios";

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;

export async function get(url, config = {}) {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const axiosApi = axios.create({
    baseURL: API_URL,
    headers: { Authorization: authUser ? authUser.type + " " + authUser.token : null }
  });

  axiosApi.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location = "/login";
      }
      Promise.reject(error);
    }
  );

  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  const authUser = url === "/auth/signin" ? null : JSON.parse(localStorage.getItem("authUser"));
  const axiosApi = axios.create({
    baseURL: API_URL,
    headers: { Authorization: authUser ? authUser.type + " " + authUser.token : null }
  });

  axiosApi.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location = "/login";
      }
      Promise.reject(error);
    }
  );

  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const axiosApi = axios.create({
    baseURL: API_URL,
    headers: { Authorization: authUser ? authUser.type + " " + authUser.token : null }
  });

  axiosApi.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location = "/login";
      }
      Promise.reject(error);
    }
  );

  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, data, config = {}) {
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const axiosApi = axios.create({
    baseURL: API_URL,
    headers: { Authorization: authUser ? authUser.type + " " + authUser.token : null }
  });

  axiosApi.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location = "/login";
      }
      Promise.reject(error);
      return error.response;
    }
  );

  return await axiosApi
    .delete(url, { data: data, ...config })
    .then(response => response);
}
