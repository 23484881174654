import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CloseButton,
  Col,
  Collapse,
  Form,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";

import { getCategories as onGetCategories } from "store/category/actions";

import { getResources as onGetResources } from "store/resource/actions";

import { getReferences as onGetReferences } from "store/reference/actions";

import { getSegments as onGetSegments } from "store/segment/actions";

import {
  addNewCustomer,
  assignRepresentative,
  assignSegment,
  customerCriteria as onGetCustomerCriteria,
  deleteCustomer as onDeleteCustomer,
  transferData
} from "store/customers/actions";

//redux
import { useDispatch, useSelector } from "react-redux";

// Column
import {
  Category,
  City,
  CreateDate,
  FacebookDate,
  FormName,
  Phone,
  Represent,
  Resource,
  Segment,
  Surname,
  UpdateDate
} from "./CustCol";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import CustomerDetail from "./CustomerDetail";
import Notification from "../../components/Notification";
import { phoneOptions } from "../../constants/phoneCodes";
import { phoneOptionsTurkish } from "../../constants/phoneCodesTurkish";
import CustomSelectableTable from "../../components/Common/CustomSelectableTable";
import { Email, Name } from "../Contacts/ContactList/contactlistCol";
import { userCriteria } from "../../store/contacts/actions";
import { getFacebookForms } from "../../store/FacebookForms/actions";

const Customers = props => {

    //meta title
    document.title = `CRM | ${props.t("Customers")}`;

    const dispatch = useDispatch();
    const { segmentId } = useParams();

    const loggedInUser = JSON.parse(localStorage.getItem("authUser"));
    const roleUser = (loggedInUser.role && loggedInUser.role.name === "ROLE_USER");
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");

    const [size, setSize] = useState(50);
    const [activeTab, setActiveTab] = useState(0);
    const [modal, setModal] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [firstCall, setFirstCall] = useState(true);
    const [resource, setResource] = useState(null);
    const [segment, setSegment] = useState(null);
    const [category, setCategory] = useState(null);
    const [salesRepresentative, setSalesRepresentative] = useState(null);
    const [reference, setReference] = useState(null);
    const [gender, setGender] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("Info");
    const [callbackClose, setCallbackClose] = useState(false);
    let [phoneCode, setPhoneCode] = useState(phoneOptions[0]);
    const [email, setEmail] = useState("");
    const [valid, setValid] = useState(true);
    const [openedTabs, setOpenedTabs] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [dropDownShown, setDropDownShown] = useState(false);
    const [represent, setRepresent] = useState(null);
    const [assignSelectedSegment, setAssignSelectedSegment] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [clearSelected, setClearSelected] = useState(false);
    const [filterResource, setFilterResource] = useState({});
    const [filterSegment, setFilterSegment] = useState({});
    const [filterCategory, setFilterCategory] = useState({});
    const [filterRepresentative, setFilterRepresentative] = useState(!roleUser ? {} : {
      label: loggedInUser.username,
      value: loggedInUser
    });
    const [filterName, setFilterName] = useState("");
    const [filterCityName, setFilterCityName] = useState("");
    const [filterPhone, setFilterPhone] = useState("");
    const [filterCreateDateStart, setFilterCreateDateStart] = useState("");
    const [filterCreateDateEnd, setFilterCreateDateEnd] = useState("");
    const [filterUpdateDateStart, setFilterUpdateDateStart] = useState("");
    const [filterUpdateDateEnd, setFilterUpdateDateEnd] = useState("");
    const [filterReference, setFilterReference] = useState("");
    const [filterUpdateUser, setFilterUpdateUser] = useState("");
    const [filterFBForm, setFilterFBForm] = useState();
    const [dataPerPage, setDataPerPage] = useState(50);
    const [isOpen, setIsOpen] = useState(false);
    const [representOpt, setRepresentOpt] = useState([]);
    const [updateUserOptions, setUpdateUserOptions] = useState([]);
    const [sizeButtonDisabled, setSizeButtonDisabled] = useState(false);

    const genderOptions = [{ label: props.t("MALE"), value: "MALE" },
      { label: props.t("FEMALE"), value: "FEMALE" }];

    const { customers, page, totalCount, totalPages, error, processResponse } = useSelector(state => ({
      customers: state.customers.customers,
      page: state.customers.page,
      totalCount: state.customers.totalCount,
      totalPages: state.customers.totalPages,
      error: state.customers.error,
      processResponse: state.customers.processResponse
    }));

    const initialBody = !roleUser ? { size: size, page: page ? page : 0, sort: "updateDate" } : {
      size: size,
      page: page ? page : 0,
      salesRepresentative: loggedInUser.id,
      sort: "updateDate"
    };

    const [body, setBody] = useState(initialBody);

    const { categories } = useSelector(state => ({
      categories: state.categories.categories
    }));

    const { segments } = useSelector(state => ({
      segments: state.segments.segments
    }));

    const { resources } = useSelector(state => ({
      resources: state.resources.resources
    }));

    const { references } = useSelector(state => ({
      references: state.references.references
    }));

    const { facebookForms } = useSelector(state => ({
      facebookForms: state.FacebookForms.facebookForms
    }));

    const resourceOptions = [];
    resources.map((item) => {
      resourceOptions.push({ label: item.name, value: item });
    });

    const segmentOptions = [];
    segments.map((item) => {
      segmentOptions.push({ label: item.name, value: item });
    });

    const categoryOptions = [];
    categories.map((item) => {
      categoryOptions.push({ label: item.name, value: item });
    });

    const fbFormOptions = [];
    facebookForms.map((item) => {
      fbFormOptions.push({ label: item.formName, value: item });
    });

    const { users } = useSelector(state => ({
      users: state.contacts.users
    }));

    useEffect(() => {
      const userReq = Object.assign(body, { notRole: "ROLE_MODERATOR", status: "ACTIVE" });
      dispatch(userCriteria(userReq));
    }, []);

    useEffect(() => {
      const representOpts = [];
      users.map((item) => {
        representOpts.push({ label: item.fullName, value: item });
      });
      setRepresentOpt(representOpts);
      setUpdateUserOptions(representOpts);
    }, [users]);

    const referenceOptions = [];
    references.map((item) => {
      referenceOptions.push({ label: item.name, value: item });
    });

    const toggleFilter = () => setIsOpen(!isOpen);

    useEffect(() => {
      if (firstCall) {
        dispatch(onGetSegments());
        setFirstCall(false);
      }
    }, [dispatch, segments]);

    useEffect(() => {
      if (firstCall) {
        dispatch(onGetCategories());
        setFirstCall(false);
      }
    }, [dispatch, categories]);

    useEffect(() => {
      if (firstCall) {
        dispatch(onGetResources());
        setFirstCall(false);
      }
    }, [dispatch, resources]);

    useEffect(() => {
      if (firstCall) {
        dispatch(onGetReferences());
        setFirstCall(false);
      }
    }, [dispatch, references]);

    useEffect(() => {
      if (firstCall) {
        dispatch(getFacebookForms());
        setFirstCall(false);
      }
    }, [dispatch, facebookForms]);

    const onChangeInResourceSelect = event => {
      setCustomer({
        ...customer,
        ["resource"]: event !== null ? event.value : null
      });
      setResource((event));
    };

    const onChangeInCategorySelect = event => {
      setCustomer({
        ...customer,
        ["category"]: event !== null ? event.value : null
      });
      setCategory((event));
    };

    const onChangeInSegmentSelect = event => {
      setCustomer({
        ...customer,
        ["segment"]: event !== null ? event.value : null
      });
      setSegment((event));
    };

    const onChangeInRepresentSelect = event => {
      setCustomer({
        ...customer,
        ["salesRepresentative"]: event !== null ? event.value : null
      });
      setSalesRepresentative((event));
    };

    const onChangeInGenderSelect = event => {
      setCustomer({
        ...customer,
        ["gender"]: event !== null ? event.value : null
      });
      setGender((event));
    };

    const onChangeInReferenceSelect = event => {
      setCustomer({
        ...customer,
        ["reference"]: event !== null ? event.value : null
      });
      setReference((event));
    };

    useEffect(() => {
      if (segmentId !== undefined) {
        const filterSeg = segmentOptions.filter(opt => (segmentId && opt.value.id.toString() === segmentId));
        if (filterSeg.length > 0) {
          setFilterSegment(filterSeg[0]);
          setBody({
            ...body,
            ["segment"]: filterSeg[0].value.id
          });
          const segReq = Object.assign(body, { segment: filterSeg[0].value.id });
          dispatch(onGetCustomerCriteria(segReq));
        }
      } else {
        dispatch(onGetCustomerCriteria(body));
      }
    }, [segmentId]);

    const onChangeFilterResource = event => {
      setFilterResource((event));
      setBody({
        ...body,
        ["resource"]: event.value.id
      });
    };

    const onChangeFilterSegment = event => {
      setFilterSegment((event));
      setBody({
        ...body,
        ["segment"]: event.value.id
      });
    };

    const onChangeFilterCategory = event => {
      setFilterCategory((event));
      setBody({
        ...body,
        ["category"]: event.value.id
      });
    };


    const onChangeFilterRepresent = event => {
      setFilterRepresentative((event));
      if (!roleUser && event.value && event.value.id) {
        setBody({
          ...body,
          ["salesRepresentative"]: event.value.id
        });
      }
    };

    const onChangeFilterReference = event => {
      setFilterReference((event));
      setBody({
        ...body,
        ["reference"]: event.value.id
      });
    };

    const onChangeFilterUpdateUser = event => {
      setFilterUpdateUser((event));
      setBody({
        ...body,
        ["updateUser"]: event.value.id
      });
    };

    const onChangeFilterFBForm = event => {
      setFilterFBForm((event));
      setBody({
        ...body,
        ["facebookForm"]: event.value.id
      });
    };

    const handleChangeFilterName = (e) => {
      setFilterName(e.target.value);
      setBody({
        ...body,
        ["fullName"]: e.target.value
      });
    };

    const handleChangeFilterCityName = (e) => {
      setFilterCityName(e.target.value);
      setBody({
        ...body,
        ["cityName"]: e.target.value
      });
    };

    const handleChangeFilterPhoneNumber = (e) => {
      setFilterPhone(e.target.value);
      setBody({
        ...body,
        ["phoneNumber"]: e.target.value
      });
    };

    function handleFilterCreateDateStart(e) {
      setFilterCreateDateStart(e.target.value);
      setBody({
        ...body,
        ["createDateStart"]: e.target.value
      });
    }

    function handleFilterCreateDateEnd(e) {
      setFilterCreateDateEnd(e.target.value);
      setBody({
        ...body,
        ["createDateEnd"]: e.target.value
      });
    }

    function handleFilterUpdateDateStart(e) {
      setFilterUpdateDateStart(e.target.value);
      setBody({
        ...body,
        ["updateDateStart"]: e.target.value
      });
    }

    function handleFilterUpdateDateEnd(e) {
      setFilterUpdateDateEnd(e.target.value);
      setBody({
        ...body,
        ["updateDateEnd"]: e.target.value
      });
    }

    const handleChange = (e) => {
      const regExpMail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const regExpText = /^(^$)|^[a-zA-Z çşğıÇŞĞIİÜüÖö]+$/;

      switch (e.target.type) {
        case "email":
          const trimmedMail = e.target.value.trim();
          setEmail(trimmedMail);
          setValid(false);
          if (regExpMail.test(email)) {
            setValid(true);
            setCustomer({
              ...customer,
              ["email"]: trimmedMail
            });
          }
          break;
        case "text":
          setCustomer({
            ...customer,
            [e.target.name]: e.target.value
          });
          break;
        case "textarea":
          setCustomer({
            ...customer,
            [e.target.name]: e.target.value
          });
          break;
        default :
          setCustomer({
            ...customer,
            [e.target.name]: e.target.value
          });
          break;
      }

    };

    const handleChangePhone = (e) => {
      let str = e.target.value.toString();
      let phoneCode = "";
      let tel = "";
      if (str.includes("+")) {
        phoneCode = str.split(/\s/)[0];
        findPhoneCode(phoneCode);
        tel = str.split(phoneCode)[1].replaceAll(/\s+/g, "");
      } else {
        tel = str.replaceAll(/\s+/g, "");
      }
      setCustomer({
        ...customer,
        ["phoneNumber"]: tel
      });
    };

    const findPhoneCode = (code) => {
      if (currentLanguage === "en") {
        phoneOptions.forEach(cd => {
          if (cd.value === code)
            setPhoneCode(cd);
        });
      } else {
        phoneOptionsTurkish.forEach(cd => {
          if (cd.value === code)
            setPhoneCode(cd);
        });
      }
    };

    const validation = {
      values: {
        segment: (customer && customer.segment) || (segment && segment.value) || null,
        category: (customer && customer.category) || (category && category.value) || null,
        resource: (customer && customer.resource) || (resource && resource.value) || null,
        salesRepresentative: (customer && customer.salesRepresentative) || (salesRepresentative && salesRepresentative.value) || null,
        reference: (customer && customer.reference) || (reference && reference.value) || null,
        firstName: (customer && customer.firstName) || "",
        lastName: (customer && customer.lastName) || "",
        phoneNumber: (customer && customer.phoneNumber) || "",
        email: (customer && customer.email) || "",
        note: (customer && customer.note) || "",
        cityName: (customer && customer.cityName) || "",
        createDate: (customer && customer.createDate) || "",
        birthday: (customer && customer.birthday) || "",
        gender: (customer && customer.gender) || null
      },

      handleSubmit: (values) => {
        const newCustomer = {
          firstName: values["firstName"],
          lastName: values["lastName"],
          phoneNumber: phoneCode.value + values["phoneNumber"],
          email: values["email"],
          note: values["note"],
          cityName: values ["cityName"],
          createDate: values["createDate"],
          birthday: values["birthday"],
          reference: values["reference"],
          salesRepresentative: values["salesRepresentative"],
          segment: values["segment"],
          category: values["category"],
          resource: values["resource"],
          gender: values["gender"]
        };

        dispatch(addNewCustomer(newCustomer));
      }
    };

    useEffect(() => {
      if (Object.keys(error).length > 0) {
        setShowNotification(true);
        setMessage(error.message);
        setNotificationType("Danger");
      } else if (processResponse) {
        setShowNotification(true);
        setMessage("Değişiklikler Kaydedildi");
        setNotificationType("Success");
        if (modal) {
          toggle();
        }
      }
    }, [error, processResponse]);

    const handleCustomerClick = row => {
      const customer = row.original;

      setCustomer({
        id: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phoneNumber: customer.phoneNumber,
        email: customer.email,
        note: customer.note,
        cityName: customer.cityName,
        birthday: customer.birthday,
        salesRepresentative: customer.salesRepresentative,
        createDate: customer.createDate,
        reference: customer.reference,
        segment: customer.segment,
        category: customer.category,
        resource: customer.resource
      });

      customer.reference ? setReference({ label: customer.reference.name, value: customer.reference }) : null;
      customer.category ? setCategory({ label: customer.category.name, value: customer.category }) : null;
      customer.resource ? setResource({ label: customer.resource.name, value: customer.resource }) : null;
      customer.segment ? setSegment({ label: customer.segment.name, value: customer.segment }) : null;
      customer.gender ? setGender({ label: customer.gender, value: customer.gender }) : null;
      customer.salesRepresentative ? setSalesRepresentative({
        label: customer.salesRepresentative.fullName,
        value: customer.salesRepresentative
      }) : null;

      const exist = checkCustomerExistOnOpenedTab(customer);
      if (exist) {
        toggleTab("toggle", exist.tabIndex, customer);
      } else {
        createTab(tabIndex + 1, customer);
      }
    };

    function handleSelectAll(data) {
      if (data.length > 0) {
        setDropDownShown(true);
        setSelectedCustomers(data);
      } else {
        setDropDownShown(false);
        setSelectedCustomers([]);
      }
    }

    function handleSelect(data) {
      if (data.length > 0) {
        setDropDownShown(true);
        setSelectedCustomers(data);
      } else {
        setDropDownShown(false);
        setSelectedCustomers([]);
      }
    }

    const columns = useMemo(
      () => [
        {
          HeaderLabel: <input
            id="mastercheck"
            type="checkbox" />,
          Header: "Action",
          accessor: (cellProps) => {
            const id = cellProps.id;
            return (<input type="checkbox" id={id} />);
          }
        },
        {
          HeaderLabel: `${props.t("Sales Representative")}`,
          accessor: "salesRepresentative.fullName",
          Cell: (cellProps) => {
            return <Represent {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Firstname")}`,
          accessor: "firstName",
          Cell: (cellProps) => {
            return <Name {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Lastname")}`,
          accessor: "lastName",
          Cell: (cellProps) => {
            return <Surname {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Phone")}`,
          accessor: "phoneNumber",
          Cell: (cellProps) => {
            return <Phone {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Segment")}`,
          accessor: "segment.name",
          Cell: (cellProps) => {
            return <Segment {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("City")}`,
          accessor: "cityName",
          Cell: (cellProps) => {
            return <City {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Resource")}`,
          accessor: "resource.name",
          Cell: (cellProps) => {
            return <Resource {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Create Date")}`,
          accessor: "createDate",
          Cell: (cellProps) => {
            return <CreateDate {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Update Date")}`,
          accessor: "updateDate",
          Cell: (cellProps) => {
            return <UpdateDate {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Facebook Date")}`,
          accessor: "facebookCreateDate",
          Cell: (cellProps) => {
            return <FacebookDate {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Form Name")}`,
          accessor: "facebookForm.formName",
          Cell: (cellProps) => {
            return <FormName {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Update User")}`,
          accessor: "updateUser.fullName",
          Cell: (cellProps) => {
            return <Category {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Category")}`,
          accessor: "category.name",
          Cell: (cellProps) => {
            return <Category {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Reference")}`,
          accessor: "reference.name",
          Cell: (cellProps) => {
            return <Category {...cellProps} />;
          }
        }
      ],
      []
    );

    const onChangePhoneCode = (e) => {
      setPhoneCode({ label: e.target.label, value: e.target.value });
    };

    const onChangeRepresent = (e) => {
      setRepresent(e);
    };

    const onChangeAssignSelectedSegment = (e) => {
      setAssignSelectedSegment(e);
    };

    const toggle = () => {
      if (modal) {
        setModal(false);
        setCustomer(null);
        setCategory({});
        setResource({});
        setSegment({});
        setGender(null);
        setSalesRepresentative({});
        setReference({});
        setEmail("");
      } else {
        setModal(true);
        setCustomer(null);
        setCategory({});
        setResource({});
        setSegment({});
        setSalesRepresentative({});
        setGender(null);
        setReference({});
        setEmail("");
        setPhoneCode(phoneOptions[0]);
      }
    };

    const closeTab = (tab) => {
      const founded = openedTabs.findIndex(item => item.tabIndex === tab.tabIndex);
      const filtered = openedTabs.filter(item => item.tabIndex !== tab.tabIndex);
      setOpenedTabs(filtered);
      if (founded === 0) {
        toggleTab("toggle", 0);
      } else {
        toggleTab("toggle", openedTabs[founded - 1].tabIndex, openedTabs[founded - 1].customer);
      }
    };

    const checkCustomerExistOnOpenedTab = (custCheck) => {
      if (openedTabs.some(openedTab => openedTab.customer.id === custCheck.id)) {
        return true;
      }
    };

    const createTab = (tabInd, cust) => {
      openedTabs.push({ tabIndex: tabInd, customer: cust });
      setOpenedTabs(openedTabs);
      setTabIndex(tabInd);
      setActiveTab(tabInd);
      toggleTab("toggle", tabInd, cust);
    };

    const toggleTab = (action, tab, cust) => {
      if (tab === 0) {
        setActiveTab(0);
        return;
      }
      if (checkCustomerExistOnOpenedTab(cust)) {
        const exist = openedTabs.filter(openedTab => openedTab.customer.id === cust.id);
        if (action === "toggle") {
          setActiveTab(exist[0].tabIndex);
          setCustomer(exist[0].customer);
        } else {
          closeTab(exist[0]);
        }
      }
    };

    //delete customer
    const [deleteModal, setDeleteModal] = useState(false);

    const handleRefresh = () => {
      dispatch(onGetCustomerCriteria(body));
    };

    const handleNavClick = (e, openedTab) => {
      if (e.target.id.split("-")[0] === ("close")) {
        closeTab(openedTab);
      } else {
        toggleTab("toggle", openedTab.tabIndex, openedTab.customer);
      }
    };
    const handleDeleteCustomer = () => {
      setDeleteModal(false);
      selectedCustomers.forEach(cust => {
          dispatch(onDeleteCustomer(cust));
          toggleTab("close", null, cust);
        }
      );

      handleRefresh();
      setSelectedCustomers([]);
      setDropDownShown(false);
    };

    const handleCustomerClicks = () => {
      toggle();
    };

    const callBackClose = (val) => {
      setMessage("");
      setShowNotification(val);
      setCallbackClose(false);
    };

    useEffect(() => {
      setActiveTab(activeTab);
    }, [activeTab]);

    const assignRepresent = () => {
      const assignRequest = Object.assign({
        salesRepresentative: represent.value,
        leadDTOList: selectedCustomers
      });
      dispatch(assignRepresentative(assignRequest));
      setSelectedCustomers([]);
      setDropDownShown(false);
      setRepresent(null);
      setOpenedTabs([]);
    };

    const handleAssignSegment = () => {
      const assignRequest = Object.assign({
        segment: assignSelectedSegment.value,
        leadDTOList: selectedCustomers
      });
      dispatch(assignSegment(assignRequest));
      setSelectedCustomers([]);
      setDropDownShown(false);
      setAssignSelectedSegment(null);
      setOpenedTabs([]);
    };

    useEffect(() => {
      if (selectedCustomers.length === 0) {
        setClearSelected(true);
      } else {
        setClearSelected(false);
      }
    }, [selectedCustomers]);

    const closeAllTabs = () => {
      toggleTab("toggle", 0, null);
      setOpenedTabs([]);
    };

    const handleFilteredCustomerData = (req) => {
      if (req) {
        dispatch(onGetCustomerCriteria(req));
      } else {
        dispatch(onGetCustomerCriteria(body));
      }
    };

    function handleClearFilterInputs() {
      setFilterSegment({});
      setFilterRepresentative({});
      setFilterResource({});
      setFilterName("");
      setFilterCityName("");
      setFilterPhone("");
      setFilterCreateDateStart("");
      setFilterCreateDateEnd("");
      setFilterUpdateDateStart("");
      setFilterUpdateDateEnd("");
      setFilterReference("");
      setFilterUpdateUser("");
      setBody(initialBody);
      handleFilteredCustomerData(initialBody);
    }

    const searchBar = (
      <div className="custom-accordion">
        <Link
          className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
          onClick={toggleFilter}
          to="#"
        >
          <i className="mdi mdi-filter font-size-15 text-secondary me-1"></i>{" "}
          {props.t("Filters")}
          <i
            className={
              isOpen
                ? "mdi mdi-chevron-up accor-down-icon ms-2"
                : "mdi mdi-chevron-down accor-down-icon ms-2"
            }
          />
        </Link>
        <Collapse isOpen={isOpen}>
          <div className="row">
            <div className="container col-11 mt-1 d-inline-block align-content-end">
              <div className="row">
                <div className="mb-3 col-xs-12 col-md-3">
                  <Label className="form-label">{props.t("Fullname")}</Label>
                  <Input
                    name="fullname"
                    type="text"
                    value={filterName}
                    onChange={(e) => handleChangeFilterName(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Phone")}</Label>
                  <Input
                    name="phone"
                    type="text"
                    value={filterPhone}
                    onChange={(e) => handleChangeFilterPhoneNumber(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("City")}</Label>
                  <Input
                    name="city"
                    type="text"
                    value={filterCityName}
                    onChange={(e) => handleChangeFilterCityName(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Resource")}</Label>
                  <Select
                    className="bs-select"
                    value={filterResource}
                    onChange={(e) => onChangeFilterResource(e)}
                    options={resourceOptions}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Category")}</Label>
                  <Select
                    className="bs-select"
                    value={filterCategory}
                    onChange={(e) => onChangeFilterCategory(e)}
                    options={categoryOptions}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Segment")}</Label>
                  <Select
                    className="bs-select"
                    value={filterSegment}
                    onChange={(e) => onChangeFilterSegment(e)}
                    options={segmentOptions}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Sales Representative")}</Label>
                  <Select
                    className="bs-select"
                    value={filterRepresentative}
                    onChange={(e) => onChangeFilterRepresent(e)}
                    options={representOpt}
                    isDisabled={roleUser}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Reference")}</Label>
                  <Select
                    className="bs-select"
                    value={filterReference}
                    onChange={(e) => onChangeFilterReference(e)}
                    options={referenceOptions}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Update User")}</Label>
                  <Select
                    className="bs-select"
                    value={filterUpdateUser}
                    onChange={(e) => onChangeFilterUpdateUser(e)}
                    options={updateUserOptions}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Facebook Forms")}</Label>
                  <Select
                    className="bs-select"
                    value={filterFBForm}
                    onChange={(e) => onChangeFilterFBForm(e)}
                    options={fbFormOptions}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Create Date Start")}</Label>
                  <Input
                    id="start-date"
                    name="start-date"
                    type="date"
                    onChange={e => handleFilterCreateDateStart(e)}
                    value={filterCreateDateStart}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Create Date End")}</Label>
                  <Input
                    id="end-date"
                    name="end-date"
                    type="date"
                    onChange={e => handleFilterCreateDateEnd(e)}
                    value={filterCreateDateEnd}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Update Date Start")}</Label>
                  <Input
                    id="update-date-start"
                    name="update-date-start"
                    type="date"
                    onChange={e => handleFilterUpdateDateStart(e)}
                    value={filterUpdateDateStart}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Update Date End")}</Label>
                  <Input
                    id="update-date-end"
                    name="update-date-end"
                    type="date"
                    onChange={e => handleFilterUpdateDateEnd(e)}
                    value={filterUpdateDateEnd}
                  />
                </div>
              </div>
            </div>
            <div className="col-1 mt-5 d-inline-block align-content-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-0"
                onClick={() => handleFilteredCustomerData()}
              >
                <i className="mdi mdi-search-web me-1" />
                {props.t("Filter")}
              </Button>
              <Button
                type="button"
                color="warning"
                className="btn-rounded mb-2 me-0"
                onClick={() => handleClearFilterInputs()}
              >
                <i className="mdi mdi-format-clear me-1" />
                {props.t("Clear All Filters")}
              </Button>
            </div>
          </div>
        </Collapse>
      </div>
    );

    function handlePreviousPage(currentPage) {
      const changedBody = Object.assign(body, { size: size, page: currentPage - 1 });
      dispatch(onGetCustomerCriteria(changedBody));
      setClearSelected(true);
    }

    function handleNextPage(currentPage) {
      const changedBody = Object.assign(body, { size: size, page: currentPage + 1 });
      dispatch(onGetCustomerCriteria(changedBody));
      setClearSelected(true);
    }

    function handleSizePerPage() {
      setSizeButtonDisabled(true);
      setSize(dataPerPage);
      const changedBody = Object.assign(body, { size: dataPerPage, page: 0 });
      dispatch(onGetCustomerCriteria(changedBody));
      setClearSelected(true);
    }

    function handleTransferData() {
      const leadDTOList = selectedCustomers.map(cust => {
        return ({
          id: cust.id,
          firstName: cust.firstName,
          lastName: cust.lastName,
          email: cust.email,
          phoneNumber: cust.phoneNumber,
          facebookId: cust.facebookId,
          facebookPageId: cust.facebookForm !== null ? cust.facebookForm.pageId : null,
          facebookFormId: cust.facebookForm !== null ? cust.facebookForm.formId : null,
          facebookFormName: cust.facebookForm !== null ? cust.facebookForm.formName : cust.facebookFormName
        });
      });
      dispatch(transferData({ leadDTOList: leadDTOList }));
      setSelectedCustomers([]);
      setOpenedTabs([]);
    }

    const toolbar = (
      <div id="toolbar" className="col-12 d-inline-block align-content-end">
        {dropDownShown && !roleUser && (
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-sm-12 d-flex">
                <div className="container">
                  <div className="row">
                    <Label className="form-label">{props.t("Sales Representative")}</Label>
                    <div className="col-7">
                      <Select
                        id="represent"
                        className="bs-select mt-2"
                        value={represent}
                        onChange={(e) => onChangeRepresent(e)}
                        options={representOpt}
                      />
                    </div>
                    <div className="col-5">
                      <Button
                        id="assign"
                        type="button"
                        color="info"
                        className="btn-sm btn-rounded m-2"
                        disabled={represent === null}
                        onClick={() => assignRepresent()}
                      >
                        <i className="mdi mdi-arrow-left-right me-1" />
                        {props.t("Assign Representative")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-sm-12 d-flex">
                <div className="container">
                  <div className="row">
                    <Label className="form-label">{props.t("Segment")}</Label>
                    <div className="col-7">
                      <Select
                        id="segment"
                        className="bs-select mt-2"
                        value={assignSelectedSegment}
                        onChange={(e) => onChangeAssignSelectedSegment(e)}
                        options={segmentOptions}
                      />
                    </div>
                    <div className="col-5">
                      <Button
                        id="assign"
                        type="button"
                        color="info"
                        className="btn-sm btn-rounded m-2"
                        disabled={assignSelectedSegment === null}
                        onClick={() => handleAssignSegment()}
                      >
                        <i className="mdi mdi-arrow-left-right me-1" />
                        {props.t("Assign Segment")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-sm-6 d-flex">
                <div className="container">
                  <div className="mt-4">
                    <div className="col-12">
                      <Button
                        id="transferdata"
                        type="button"
                        className="btn-rounded btn-info m-2"
                        onClick={() => handleTransferData()}
                      >
                        <i className="mdi mdi-send me-1" />
                        {props.t("Transfer Data")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-sm-6 d-flex">
                <div className="container">
                  <div className="mt-4">
                    <div className="col-12">
                      <Button
                        id="delete"
                        type="button"
                        color="danger"
                        className="btn-rounded m-3"
                        onClick={() => setDeleteModal(true)}
                      >
                        <i className="mdi mdi-delete me-1" />
                        {props.t("Delete")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );

    return (
      <React.Fragment>
        <DeleteModal
          id="deleteModal"
          show={deleteModal}
          onDeleteClick={handleDeleteCustomer}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div id="container" className="container-fluid">
          <Notification
            type={notificationType}
            message={message}
            show={showNotification}
            callbackShow={callBackClose}
          />
          <Breadcrumbs title={props.t("Customers")} />
          <Row>
            <Card id="custList">
              <CardTitle>
                <Row className="mt-4">
                  <Col lg="10">
                  </Col>
                  <Col lg="2">
                    {openedTabs.length > 0 ? (
                      <Button
                        id="closeTabs"
                        type="button"
                        color="warning"
                        className="btn-rounded btn-sm mt-2"
                        onClick={() => closeAllTabs()}
                      >
                        <i className="mdi mdi-crosshairs me-1" />
                        {props.t("Close All Tabs")}
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </CardTitle>
              <CardBody>
                <div className="crypto-buy-sell-nav" id="contain">
                  <Nav tabs className="nav-tabs-custom" role="tablist">
                    <NavItem key={0}>
                      <NavLink
                        className={classnames({
                          active: activeTab === 0
                        })}
                        onClick={() => {
                          toggleTab("toggle", 0);
                        }}
                      >
                        {props.t("Customers")}
                      </NavLink>
                    </NavItem>
                    {openedTabs.length > 0 ? (
                      openedTabs.map((openedTab, key) => (
                        <NavItem id={`nav-item${key}`} key={key} className="nav-item">
                          <NavLink
                            id={`link-${key}`}
                            className={classnames({
                              active: activeTab === openedTab.tabIndex
                            }, "nav-link")}
                            onClick={(e) => handleNavClick(e, openedTab)}
                          >
                            <span>{`${openedTab.customer.firstName} ${openedTab.customer.lastName}`} </span>
                            <CloseButton
                              id={`close-${key}`}
                              className="btn-xs p-0"
                              onClick={(event) => handleNavClick(event, openedTab)}
                            />
                          </NavLink>
                        </NavItem>
                      ))
                    ) : null}
                  </Nav>
                  <TabContent
                    id="tab-content"
                    activeTab={activeTab}
                    className="p-3"
                  >
                    <TabPane tabId={0} id="custListTab">
                      {searchBar}
                      <React.Fragment>
                        <Col className="col-12 mt-2">
                          {props.t("Total Data Count")} = {totalCount}
                        </Col>
                        <div>
                          <CustomSelectableTable
                            refreshButton={true}
                            handleRefreshClick={handleRefresh}
                            columns={columns}
                            data={customers}
                            isAddOptions={true}
                            handleAddClick={handleCustomerClicks}
                            handleRowClick={handleCustomerClick}
                            customPageSize={size}
                            handleChangeSelect={handleSelect}
                            handleChangeSelectAll={handleSelectAll}
                            toolbar={toolbar}
                            clearAllSelected={clearSelected}
                            className="custom-header-css"
                            isExportEnabled={loggedInUser.role.name === "ROLE_MODERATOR"}
                          />
                          <Row className="col-12 mb-5">
                            <Col className="col-3">
                              <InputGroup>
                                <Input
                                  min={0}
                                  style={{ width: 70 }}
                                  max={totalPages}
                                  value={dataPerPage}
                                  onChange={(e) => setDataPerPage(e.target.value)}
                                />
                                <Button size="sm" className="btn btn-outline-secondary" disabled={sizeButtonDisabled}
                                        onClick={handleSizePerPage}>{props.t("Set Size")}
                                </Button>
                              </InputGroup>
                            </Col>
                            <Col className="col-6" />
                            <Col className="col-1 align-content-end">
                              <div className="btn-group me-0" role="group">
                                <button type="button" className="btn btn-outline-secondary" disabled={page === 0}
                                        onClick={() => handlePreviousPage(page)}>
                                  <i className="bx bx-chevron-left"></i>
                                </button>
                                <button type="button" className="btn btn-outline-secondary">
                                  {page + 1}
                                </button>
                                <button type="button" className="btn btn-outline-secondary"
                                        disabled={page === totalPages}
                                        onClick={() => handleNextPage(page)}>
                                  <i className="bx bx-chevron-right"></i>
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <Modal isOpen={modal} toggle={toggle} size="lg">
                          <ModalHeader toggle={toggle} tag="h4">
                            {props.t("Add")}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              id="new-customer"
                              onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit(validation.values);
                                return false;
                              }}
                            >
                              <Row form="true">
                                <Col className="col-12">
                                  <Row className="row-cols-2">
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Firstname")}</Label>
                                      <Input
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        onChange={handleChange}
                                        value={validation.values.firstName || ""}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Lastname")}</Label>
                                      <Input
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        onChange={handleChange}
                                        value={validation.values.lastName || ""}
                                      />
                                    </div>
                                  </Row>
                                  <Row className="row-cols-2">
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Phone")}</Label>
                                      <InputGroup>
                                        <select
                                          id="phoneCode"
                                          className="form-select"
                                          value={phoneCode.value}
                                          onChange={onChangePhoneCode}
                                        >
                                          {currentLanguage === "en" ? (
                                            phoneOptions.map(code => (
                                              <option id={code.value} key={code.label} value={code.value}>
                                                {code.label}
                                              </option>
                                            ))
                                          ) : (phoneOptionsTurkish.map(code => (
                                            <option id={code.value} key={code.label} value={code.value}>
                                              {code.label}
                                            </option>
                                          )))}
                                        </select>
                                        <Input
                                          id="phoneNum"
                                          name="phoneNumber"
                                          type="text"
                                          onChange={handleChangePhone}
                                          value={validation.values.phoneNumber || ""}
                                          required={true}
                                          placeholder="--- --- ----"
                                          invalid={!validation.values.phoneNumber}
                                        />
                                      </InputGroup>
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Email")}</Label>
                                      <Input
                                        id="email"
                                        name="email"
                                        type="email"
                                        onChange={handleChange}
                                        value={email}
                                        required={false}
                                        invalid={email.length !== 0 && !valid}
                                      />
                                    </div>
                                  </Row>
                                  <Row className="row-cols-2">
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("City")}</Label>
                                      <Input
                                        id="cityName"
                                        name="cityName"
                                        type="text"
                                        onChange={handleChange}
                                        value={validation.values.cityName || ""}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Birth Date")}</Label>
                                      <Input
                                        id="birthday"
                                        name="birthday"
                                        type="date"
                                        onChange={handleChange}
                                        value={validation.values.birthday || ""}
                                      />
                                    </div>
                                  </Row>
                                  <div className="mb-3">
                                    <Label className="form-label">{props.t("Note")}</Label>
                                    <Input
                                      id="note"
                                      name="note"
                                      type="textarea"
                                      rows="1"
                                      onChange={handleChange}
                                      value={validation.values.note || ""}
                                    />
                                  </div>
                                  <Row className="row-cols-2">
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Reference")}</Label>
                                      <Select
                                        id="referenceSelect"
                                        value={reference}
                                        onChange={(e) => {
                                          onChangeInReferenceSelect(e);
                                        }}
                                        options={referenceOptions}
                                        isClearable={true}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Gender")}</Label>
                                      <Select
                                        id="genderSelect"
                                        value={gender}
                                        onChange={(e) => {
                                          onChangeInGenderSelect(e);
                                        }}
                                        options={genderOptions}
                                        isClearable={true}
                                      />
                                    </div>
                                  </Row>
                                  <Row className="row-cols-2">
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Sales Representative")}</Label>
                                      <Select
                                        id="representSelect"
                                        value={salesRepresentative}
                                        onChange={(e) => {
                                          onChangeInRepresentSelect(e);
                                        }}
                                        options={representOpt}
                                        isClearable={true}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Resource")}</Label>
                                      <Select
                                        id="resourceSelect"
                                        value={resource}
                                        onChange={(e) => {
                                          onChangeInResourceSelect(e);
                                        }}
                                        options={resourceOptions}
                                        isClearable={true}
                                      />
                                    </div>
                                  </Row>
                                  <Row className="row-cols-2">
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Segment")}</Label>
                                      <Select
                                        id="segmentSelect"
                                        value={segment}
                                        onChange={(e) => {
                                          onChangeInSegmentSelect(e);
                                        }}
                                        options={segmentOptions}
                                        isClearable={true}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label className="form-label">{props.t("Category")}</Label>
                                      <Select
                                        id="categorySelect"
                                        value={category}
                                        onChange={(e) => {
                                          onChangeInCategorySelect(e);
                                        }}
                                        options={categoryOptions}
                                        isClearable={true}
                                      />
                                    </div>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-end">
                                    <button
                                      id="save"
                                      type="submit"
                                      className="btn btn-success save-customer"
                                    >
                                      {props.t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                      </React.Fragment>
                    </TabPane>
                    {openedTabs.map((openedTab, key) =>
                      (
                        <TabPane key={key} tabId={openedTab.tabIndex} id={`custDetail${openedTab.customer.id}`}>
                          <CustomerDetail
                            id={openedTab.customer.id}
                            customer={openedTab.customer}
                            representOptions={representOpt}
                            resourceOptions={resourceOptions}
                            segmentOptions={segmentOptions}
                            categoryOptions={categoryOptions}
                            referenceOptions={referenceOptions}
                            genderOptions={genderOptions}
                          />
                        </TabPane>
                      ))}
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </Row>
        </div>
      </React.Fragment>
    );
  }
;
Customers.propTypes = {
  t: PropTypes.any,
  customers: PropTypes.array,
  onAddNewCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
  onGetSegments: PropTypes.func,
  segmentId: PropTypes.any
};

export default withRouter(withTranslation()(Customers));
