import {
  ADD_REMINDER_FAIL,
  ADD_REMINDER_SUCCESS,
  DELETE_REMINDER_FAIL,
  DELETE_REMINDER_SUCCESS,
  GET_LEAD_REMINDERS_FAIL,
  GET_LEAD_REMINDERS_SUCCESS,
  GET_REMINDER_COUNT_FAIL,
  GET_REMINDER_COUNT_SUCCESS,
  GET_REMINDER_CRITERIA_FAIL,
  GET_REMINDER_CRITERIA_SUCCESS,
  GET_REMINDERS_FAIL,
  GET_REMINDERS_SUCCESS,
  GET_TODAY_REMINDERS_FAIL,
  GET_TODAY_REMINDERS_SUCCESS,
  GET_USER_REMINDERS_FAIL,
  GET_USER_REMINDERS_SUCCESS,
  UPDATE_REMINDER_FAIL,
  UPDATE_REMINDER_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  reminders: [],
  reminderCount:0,
  error: {}
};

const Reminder = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REMINDERS_SUCCESS:
      return {
        ...state,
        reminders: action.payload
      };

    case GET_REMINDERS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_LEAD_REMINDERS_SUCCESS:
      return {
        ...state,
        reminders: action.payload
      };

    case GET_LEAD_REMINDERS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_USER_REMINDERS_SUCCESS:
      return {
        ...state,
        reminders: action.payload
      };

    case GET_USER_REMINDERS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_REMINDER_SUCCESS:
      return {
        ...state,
        reminders: [...state.reminders, action.payload]
      };

    case ADD_REMINDER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_REMINDER_SUCCESS:
      return {
        ...state,
        reminders: state.reminders.map(reminder =>
          reminder.id.toString() === action.payload.id.toString()
            ? { reminder, ...action.payload }
            : reminder
        )
      };

    case UPDATE_REMINDER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_REMINDER_SUCCESS:
      return {
        ...state,
        reminders: state.reminders.filter(
          reminder => reminder.id.toString() !== action.req.toString()
        )
      };

    case DELETE_REMINDER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_TODAY_REMINDERS_SUCCESS:
      return {
        ...state,
        reminders: action.payload
      };

    case GET_TODAY_REMINDERS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_REMINDER_CRITERIA_SUCCESS:
      return {
        ...state,
        reminders: action.payload.content
      };

    case GET_REMINDER_CRITERIA_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_REMINDER_COUNT_SUCCESS:
      return {
        ...state,
        reminderCount: action.payload
      };

    case GET_REMINDER_COUNT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default Reminder;
